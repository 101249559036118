import { motion } from 'framer-motion';
import { useRef } from 'react';

const Button = (props) => {
	return (
		<a
			href={props.href}
			className={`group relative flex h-[50px] items-center justify-center ${props.className}`}>
			<span
				className={`relative left-0 right-0 z-10 mx-auto text-lg font-bold leading-tight transition-all ${props.textColor}`}>
				{props.text}
			</span>
			<span
				className={`absolute left-0 top-0 z-0 h-[50px] rounded-full transition-all group-hover:w-full ${props.bgClass}`}></span>
		</a>
	);
};

export default Button;
