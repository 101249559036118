import { Button } from '../';
import { motion } from 'framer-motion';

const Hero = () => {
     return (
          <>
               <section
                    id='Hero'
                    className='relative z-50 mx-auto mt-[100px] flex w-full grow flex-col items-stretch bg-gray-100 p-4 lg:p-6 xl:pt-8'
               >
                    <div className='mx-auto grid max-w-9xl grid-cols-1 overflow-hidden rounded-3xl border bg-white md:grid-cols-2'>
                         <div className="h-[16rem] bg-[url('./images/img-hero.jpg')] bg-cover bg-top sm:h-[18rem] md:h-full"></div>
                         <motion.div
                              id='introCopy'
                              className='justify-center bg-white p-4 sm:p-8 sm:pt-4 md:flex md:flex-col md:py-20'
                              whileInView={{ y: 0, opacity: 1 }}
                              initial={{ y: 50, opacity: 0 }}
                              transition={{ delay: 0.5, duration: 1 }}
                         >
                              <div className='max-w-[40rem]'>
                                   <h1 className='mb-4 mt-2 text-center text-[2.1rem] font-bold leading-[2.4rem] tracking-tighter sm:text-[3rem] sm:leading-[3.3rem] md:mt-0 md:text-left md:text-[2.3rem] md:leading-[2.6rem] lg:text-[3.3rem] lg:leading-[3.8rem] 2xl:text-[4rem] 2xl:leading-[4.4rem]'>
                                        <span className='bg-gradient-to-r from-gray-400 to-gray-600 bg-clip-text text-transparent'>
                                             Home Inspections&nbsp;
                                        </span>
                                        <span className='block bg-gradient-to-br from-yellow-600 to-yellow-400 bg-clip-text text-transparent'>
                                             you can trust.
                                        </span>
                                   </h1>
                                   <h3 className='mt-8 text-center text-lg font-black sm:text-xl md:text-left md:text-2xl'>
                                        Certified & Licensed Home Inspections.
                                   </h3> 
                                   <p className='mb-8 text-center md:text-left 2xl:mb-16'>
                                        We provide a wide range of thorough home
                                        inspection services for residents in
                                        Prescott/Phoenix and surrounding areas.
                                        Give us a call today to schedule yours!
                                   </p>
                                   <Button
                                        href='#Services'
                                        className='mr-auto ml-auto w-56 md:ml-0'
                                        text='Our Services'
                                        bgClass='bg-gray-600 group-hover:bg-yellow-600 w-full'
                                        textColor='text-white hover:text-white'
                                   />
                              </div>
                         </motion.div>
                    </div>
               </section>
          </>
     );
};

export default Hero;
