import {
	CheckBadgeIcon,
	CurrencyDollarIcon,
	HomeModernIcon,
	WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';

const serviceInfo = [
	{
		name: 'Pre-Purchase Inspections',
		icon: CurrencyDollarIcon,
		copy: 'Buy your next home with confidence.',
	},
	{
		name: 'Pre-Listing Inspections',
		icon: HomeModernIcon,
		copy: "Know your home's condition before you sell.",
	},
	{
		name: '1 & 2 Year Warranty Inspections',
		icon: CheckBadgeIcon,
		copy: 'Ensure that your newly built home is safe from defects.',
	},
	{
		name: 'Maintenance Inspections',
		icon: WrenchScrewdriverIcon,
		copy: 'Maintain your home with an annual inspection.',
	},
];

const Service = (props) => {
	return (
		<div className={props.className}>
			{serviceInfo.map((item) => (
				<div className='group relative rounded-3xl bg-white p-6 shadow-gray-900/10 ring-1 ring-gray-200 transition-all duration-700 hover:z-50 hover:shadow-2xl hover:ring-gray-200 md:py-8 lg:py-10'>
					<h4 className='text-md mb-4 font-black tracking-tight text-gray-600 sm:text-[1.1rem] md:text-left lg:text-[1.4rem]'>
						<item.icon
							className='mr-3 inline h-9 w-9 text-yellow-600'
							aria-hidden='true'
						/>
						{item.name}
					</h4>
					<p className='m-0 text-[1rem] leading-[1.4rem] text-gray-500 md:text-[1.2rem] md:leading-[1.8rem]'>
						{item.copy}
					</p>
					<span className='absolute left-0 right-0 -top-px z-0 mx-auto h-px w-3/4 bg-gradient-to-r from-yellow-800/0 via-yellow-400 to-yellow-500/0 transition-all duration-700'></span>
					<span className='absolute left-0 right-0 -bottom-px z-0 mx-auto h-px w-3/4 bg-gradient-to-r from-yellow-800/0 via-yellow-400 to-yellow-500/0 transition-all duration-700'></span>
				</div>
			))}
		</div>
	);
};
export default Service;
