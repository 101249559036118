const faq = [
     {
          question: 'Is It Okay to Perform the Inspection Myself?',
          answer: 'Inspecting your own potential home is not recommended. Home inspectors are specially trained to look for things you may miss. Unless you are a home inspector yourself, please seek out a professional. Some states or financial lenders require a licensed inspector to complete the inspection.',
     },
     {
          question: 'What Is the Cost of a Home Inspection?',
          answer: 'The price varies by square footage and age of the home. Call for a quote!',
     },
     {
          question: 'What Is Covered Under This Inspection?',
          answer: 'Castle Home Inspection adheres to the state of Arizona professional standards of practice for home inspectors. Visit https://btr.az.gov/resources/standards to learn more.',
     },
     {
          question:
               'Does the Inspector Need to Specialize in Residential Inspection?',
          answer: "Ask about an inspector's specialties. There are various types of real estate, and all need varying forms of inspection before purchase. Commercial real estate is different from residential real estate, which is different from multi-family real estate. Castle Home Inspection is specialized in single family, or smaller multi-family residential Home Inspection.",
     },
     {
          question:
               'What Type of Inspection Report is Supplied and How Long Does it Take to Submit?',
          answer: 'At Castle Home Inspection, we provide a digital copy of the report onsite. In many states, a report showing that an inspection was completed and what the inspection found is required before purchase. There is typically an agreed to Inspection period of 10-14 days, for a buyer to have a home inspected, and negotiate any unforeseen issues found during the Home Inspection. As with the specific State requirements for what is inspected, there is usually a requirement for what is listed.',
     },
     {
          question: 'Is Anyone Allowed or Required to Attend the Inspection?',
          answer: 'The home inspection is a valuable experience for you as a home buyer and future home owner. The inspector should allow you to attend the inspection. You will be able to see firsthand what potential problems exist now or may arise soon. Knowing what looks normal and what a problem looks like will help you later in maintaining your home. Castle Home Inspection will walk you through, as well as provide you a digital copy of the Report at the end of the inspection.',
     },
     {
          question: 'Is it Possible for a Home to Fail an Inspection?',
          answer: 'A Home Inspection is meant to give you a better overall outlook of the home, and any deficiencies it may have. There may be some requirements that the house needs to meet in order for your financial lender to lend you the money for the home, depending on the type of loan. This does not mean that the house has “failed” the inspection and will not be able to be sold.',
     },
     {
          question:
               'What Happens if There Are Problems Found During the Inspection?',
          answer: 'Any deficiencies noted will be found in the report for your personally information and should be discussed with your realator',
     },
];

const Faq = () => {
     return (
          <section
               id='Faq'
               className='bg-gray-100'
          >
               <div className='mx-auto max-w-8xl p-4 sm:py-9 md:py-12'>
                    <div className='grid grid-cols-12 gap-8'>
                         <div className='col-span-12 md:col-span-6'>
                              <div className='sticky top-[160px]'>
                                   <h2 className='mb-6 bg-gradient-to-br from-yellow-600 to-yellow-400 bg-clip-text text-center text-[2.1rem] font-bold leading-[2.4rem] tracking-tighter text-transparent md:text-left'>
                                        Frequently Asked Questions
                                   </h2>
                                   <p className='text-center md:text-left'>
                                        Here's a list of questions we get often.
                                        If you can't find what you're looking
                                        for here, please give us a call.
                                   </p>
                              </div>
                         </div>
                         <div className='col-span-12 text-center md:col-span-6 md:text-left'>
                              {faq.map((faq) => (
                                   <figure>
                                        <span className='text-md mb-2 block font-black tracking-tight text-gray-600 sm:text-[1.1rem] md:text-left lg:text-[1.4rem]'>
                                             {faq.question}
                                        </span>
                                        <span className='text-sm'>
                                             {faq.answer}
                                        </span>
                                        <hr className='my-6' />
                                   </figure>
                              ))}
                         </div>
                    </div>
               </div>
          </section>
     );
};

export default Faq;
