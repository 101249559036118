import { useScrollPosition } from '../../hooks/useScrollPosition';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

const Mark = () => {
	const scrollPosition = useScrollPosition();
	return (
		<a
			href='/'
			className='relative origin-left'
			title='Castle Home Inspections&#8482;'>
			<svg
				id='castle-mark'
				className={classNames(
					scrollPosition > 0
						? 'absolute left-0 top-[.01rem] z-30 h-[60px] opacity-0 transition-all delay-300 duration-500'
						: 'absolute top-[.01rem] left-[.865rem] z-30 h-[60px] opacity-0 transition-all duration-500'
				)}
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 21.13 14.76'>
				<g fill='white'>
					<path d='M17.5,11.08h-7.52c-.4,0-.72,.32-.72,.72v2.23c0,.39,.32,.71,.72,.71h7.52c.39,0,.72-.32,.72-.71v-2.23c0-.4-.32-.72-.72-.72Z' />
					<path d='M15.14,6.5h5.15c.4,0,.72,.32,.72,.72v2.23c0,.4-.32,.72-.72,.72h-5.14c-.4,0-.72-.32-.72-.72v-2.23c0-.39,.32-.72,.72-.72Z' />
					<path d='M5.51,6.5h7.52c.39,0,.72,.32,.72,.72v2.23c0,.4-.32,.72-.72,.72H5.51c-.4,0-.72-.32-.72-.72v-2.23c0-.4,.32-.72,.72-.72Z' />
					<path d='M2.45,10.16c.51,0,1.1-.07,1.29-.16,.2-.09,.36-.98,.36-1.49v-.36c0-.52-.16-1.4-.36-1.49-.2-.09-.78-.16-1.29-.16h-.81c-.51,0-1.1,.07-1.29,.16-.2,.09-.35,.57-.35,.58s.09,.44,.19,.94l.23,1.06c.11,.5,.23,.92,.25,.92,.02,0,.51,0,1.03,0h.75Z' />
					<path d='M8.22,11.24c-.2-.09-.78-.16-1.29-.16H1.79c-.51,0-.83,.41-.7,.91l.52,1.86c.15,.49,.69,.9,1.2,.9H6.93c.52,0,1.1-.07,1.29-.16,.2-.09,.36-.98,.36-1.49v-.36c0-.52-.16-1.4-.36-1.49Z' />
					<path d='M20.4,11.08s.01,0,.02,0c.04,0,.09,0,.13,0h-.15Z' />
					<path d='M20.42,11.08c-.48,.01-.98,.08-1.16,.16-.2,.09-.36,.98-.36,1.49v.36c0,.52,.11,1.09,.16,1.3,.06,.26,.21,.38,.72,.37,.71-.02,.63-.34,.8-.91l.52-1.86c.13-.49-.18-.89-.68-.9Z' />
					<path d='M1.18,5.69H5.64c.51,0,.94-.34,.94-.74V.74c0-.41-.42-.74-.94-.74H1.18C.67,0,.25,.33,.25,.74V4.94c0,.41,.42,.74,.94,.74Z' />
					<path d='M15.69,5.69h4.45c.52,0,.94-.34,.94-.74V.74c0-.41-.42-.74-.94-.74h-4.45c-.52,0-.94,.33-.94,.74V4.94c0,.41,.42,.74,.94,.74Z' />
				</g>
			</svg>
		</a>
	);
};

export default Mark;
