import React from 'react';

const About = () => {
     return (
          <>
               <section
                    id='About'
                    className='relative bg-white'
               >
                    <div className='mx-auto max-w-6xl p-4 sm:py-9 md:py-12'>
                         <div className='grid grid-cols-1 text-center'>
                              <h2 className='mb-2 bg-gradient-to-br from-yellow-600 to-yellow-400 bg-clip-text text-[2.1rem] font-bold leading-[2.4rem] tracking-tighter text-transparent'>
                                   About Your Home Inspector
                              </h2>
                              <h3 className='text-md mb-4 font-black tracking-tight text-gray-600 sm:text-[1.1rem] lg:text-[1.4rem]'>
                                   Todd Buck
                              </h3>
                              <p className='text-center text-[16px] leading-6'>
                                   As the owner of Castle Home Inspection it is
                                   my privilege to provide the most thorough and
                                   detailed home inspection for you. I founded
                                   this company to furnish the most personalized
                                   and up to date inspection for your current
                                   and future purchases so that you may have
                                   peace of mind with one of the largest
                                   financial investments we make. To provide you
                                   with the best service I am ASHI certified,
                                   AHIT certified, and AZ board of technical
                                   registration certified and licensed. I supply
                                   inspections for purchasing, pre list
                                   inspection, warranty inspection, and
                                   maintenance inspection. Purchasing a home can
                                   be stressful but reading through your
                                   detailed report shouldn't be. On top of
                                   providing you with a comprehensive report I
                                   am also here to help you navigate it and
                                   answer any questions you may have. I
                                   appreciate the opportunity to work for you
                                   and pledge to do just that. To schedule a
                                   free quote for your inspection give me a
                                   call!
                              </p>
                         </div>
                    </div>
               </section>
          </>
     );
};

export default About;
