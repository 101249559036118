import { Button, Service } from '../';
import { motion } from 'framer-motion';

const Services = () => {
     return (
          <>
               <section
                    id='Services'
                    className='relative bg-white'
               >
                    <div className='mx-auto max-w-8xl p-4 sm:py-9 md:py-12'>
                         <div className='grid grid-cols-1 text-center'>
                              <h2 className='mb-6 bg-gradient-to-br from-yellow-600 to-yellow-400 bg-clip-text text-[2.1rem] font-bold leading-[2.4rem] tracking-tighter text-transparent'>
                                   Services
                              </h2>
                              <p className='mb-8 text-center 2xl:mb-16'>
                                   Contact us today to get a free quote on any
                                   of our four home inspection services.
                              </p>
                         </div>
                         <div className='grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-12'>
                              <Service className='mx-auto grid w-full grid-cols-1 gap-4 lg:col-span-5 lg:gap-8' />
                              <div className='grid grid-cols-2 gap-4 lg:col-span-7 lg:gap-8'>
                                   <div className='row-span-2 h-full w-full rounded-3xl bg-sky-500 bg-[url(./images/services-1.jpg)] bg-cover bg-top shadow-lg shadow-slate-900/20'></div>
                                   <div className='h-48 w-full rounded-3xl bg-sky-500 bg-[url(./images/services-2.jpg)] bg-cover bg-center shadow-lg shadow-slate-900/20 md:h-full'></div>
                                   <div className='h-48 w-full rounded-3xl bg-sky-500 bg-[url(./images/services-3.jpg)] bg-cover bg-center shadow-lg shadow-slate-900/20 md:h-full'></div>
                              </div>
                         </div>
                    </div>
               </section>
          </>
     );
};

export default Services;
