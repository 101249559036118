import React from 'react';
import { Header, Hero, Services, Footer } from './components';
import About from './components/sections/About';
import Faq from './components/sections/Faq';

const App = () => {
     return (
          <div className='flex min-h-screen flex-col antialiased transition-all duration-500'>
               <Header />
               <Hero />
               <Services />
               <Faq />
               <About />
               <Footer />
          </div>
     );
};

export default App;
