import ashi from '../../images/ashi-member.webp';

export default function Footer() {
     return (
          <footer className='z-[9999] bg-gray-900'>
               <div className='mx-auto max-w-7xl overflow-hidden text-center'>
                    <img
                         className='mx-auto mt-10 w-36'
                         src={ashi}
                         alt='Ashi Member'
                    />
                    <p className='m-0 px-4 py-8 text-center text-[.8rem] text-gray-300 sm:py-12 sm:text-[1rem]'>
                         <span className='mt-0 mb-4 block text-sm font-black tracking-tighter text-gray-300 md:text-lg lg:text-xl'>
                              <span className='text-yellow-600'>Call Us: </span>
                              (928) 830-0630
                         </span>
                         <p className='text-[18px] font-bold text-white'>
                              Prescott, AZ 86303
                         </p>
                         &copy; {new Date().getFullYear()}{' '}
                         <span className='text-yellow-600'>
                              Castle Home Inspection, LLC.
                         </span>{' '}
                         All rights reserved.
                    </p>
               </div>
          </footer>
     );
}
