import { Logo, Button } from '../';
import { useScrollPosition } from '../../hooks/useScrollPosition';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

const Header = () => {
	const scrollPosition = useScrollPosition();

	return (
		<header
			className={classNames(
				scrollPosition > 0 ? 'bg-white/80 backdrop-blur-sm' : 'bg-white',
				'flex-center fixed top-0 left-0 right-0 z-[99999] h-[100px] w-full border-b transition-all duration-500'
			)}>
			<div className='mx-auto w-full max-w-9xl px-8'>
				<div className='flex items-center justify-between gap-8 md:grid-cols-3'>
					<Logo
						wrapperClass='block max-w-[150px]'
						className='w-full'
					/>
					<p className='m-0 hidden text-sm font-black tracking-tighter text-gray-600 sm:block md:text-lg lg:text-xl'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 24 24'
							fill='currentColor'
							className='mr-2 inline h-6 w-6 text-yellow-500'>
							<path
								fillRule='evenodd'
								d='M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z'
								clipRule='evenodd'
							/>
						</svg>
						(928) 830-0630
					</p>
					<div className='group relative max-w-max'>
						<p className='absolute right-[2rem] z-10 text-sm font-bold text-yellow-600 transition-all group-hover:opacity-0'>
							Call for a
						</p>
						<Button
							href='tel:928-830-0630'
							className='w-36'
							text='Free Quote'
							bgClass='w-[50px] h-[50px] bg-gray-300 group-hover:bg-yellow-600'
							textColor='text-gray-600 group-hover:text-white'
						/>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
